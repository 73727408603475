<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 14.0572C9.22883 14.0572 9.42067 13.9798 9.5755 13.825C9.73033 13.6701 9.80775 13.4783 9.80775 13.2495C9.80775 13.0206 9.73033 12.8288 9.5755 12.674C9.42067 12.5193 9.22883 12.442 9 12.442C8.77117 12.442 8.57933 12.5193 8.4245 12.674C8.26967 12.8288 8.19225 13.0206 8.19225 13.2495C8.19225 13.4783 8.26967 13.6701 8.4245 13.825C8.57933 13.9798 8.77117 14.0572 9 14.0572ZM9.00025 10.5765C9.21292 10.5765 9.391 10.5046 9.5345 10.361C9.67817 10.2171 9.75 10.039 9.75 9.82645V5.28795C9.75 5.07545 9.67808 4.89737 9.53425 4.7537C9.39042 4.60987 9.21225 4.53795 8.99975 4.53795C8.78708 4.53795 8.609 4.60987 8.4655 4.7537C8.32183 4.89737 8.25 5.07545 8.25 5.28795V9.82645C8.25 10.039 8.32192 10.2171 8.46575 10.361C8.60958 10.5046 8.78775 10.5765 9.00025 10.5765ZM6.20975 17.8457C5.96575 17.8457 5.73325 17.7989 5.51225 17.7052C5.29125 17.6117 5.09875 17.4829 4.93475 17.3187L1.027 13.411C0.862833 13.247 0.734 13.0545 0.6405 12.8335C0.546833 12.6125 0.5 12.38 0.5 12.136V6.55545C0.5 6.31145 0.546833 6.07895 0.6405 5.85795C0.734 5.63695 0.862833 5.44445 1.027 5.28045L4.93475 1.3727C5.09875 1.20854 5.29125 1.0797 5.51225 0.986203C5.73325 0.892536 5.96575 0.845703 6.20975 0.845703H11.7902C12.0343 0.845703 12.2668 0.892536 12.4878 0.986203C12.7087 1.0797 12.9012 1.20854 13.0652 1.3727L16.973 5.28045C17.1372 5.44445 17.266 5.63695 17.3595 5.85795C17.4532 6.07895 17.5 6.31145 17.5 6.55545V12.136C17.5 12.38 17.4532 12.6125 17.3595 12.8335C17.266 13.0545 17.1372 13.247 16.973 13.411L13.0652 17.3187C12.9012 17.4829 12.7087 17.6117 12.4878 17.7052C12.2668 17.7989 12.0343 17.8457 11.7902 17.8457H6.20975Z"
      fill="#2F858D"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>
