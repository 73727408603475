<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3845 18.615C17.6295 18.615 17.8382 18.5287 18.0105 18.3562C18.183 18.1839 18.2693 17.9752 18.2693 17.7302C18.2693 17.4854 18.183 17.2768 18.0105 17.1045C17.8382 16.932 17.6295 16.8457 17.3845 16.8457C17.1397 16.8457 16.9311 16.932 16.7587 17.1045C16.5862 17.2768 16.5 17.4854 16.5 17.7302C16.5 17.9752 16.5862 18.1839 16.7587 18.3562C16.9311 18.5287 17.1397 18.615 17.3845 18.615ZM10 19.8457C8.33283 19.8457 6.783 19.4422 5.3505 18.6352C3.91817 17.828 2.78658 16.7392 1.95575 15.3687L6.1155 11.2092L8.75775 13.467C8.93975 13.631 9.14842 13.7046 9.38375 13.688C9.61892 13.6713 9.81858 13.581 9.98275 13.417L14.25 9.17445V10.3457C14.25 10.5582 14.3219 10.7363 14.4658 10.88C14.6096 11.0238 14.7878 11.0957 15.0003 11.0957C15.2129 11.0957 15.391 11.0238 15.5345 10.88C15.6782 10.7363 15.75 10.5582 15.75 10.3457V7.49945C15.75 7.24345 15.6634 7.02879 15.4902 6.85545C15.3169 6.68229 15.1022 6.5957 14.8462 6.5957H12C11.7875 6.5957 11.6094 6.66762 11.4658 6.81145C11.3219 6.95529 11.25 7.13345 11.25 7.34595C11.25 7.55862 11.3219 7.7367 11.4658 7.8802C11.6094 8.02387 11.7875 8.0957 12 8.0957H13.1807L9.31925 11.9572L6.64225 9.72445C6.46192 9.57062 6.25533 9.49787 6.0225 9.5062C5.7895 9.51454 5.591 9.60079 5.427 9.76495L1.2155 13.9765C0.982167 13.4521 0.804583 12.8565 0.68275 12.1897C0.560917 11.5229 0.5 10.9082 0.5 10.3457C0.5 9.03154 0.749333 7.79654 1.248 6.6407C1.74667 5.48487 2.42342 4.47945 3.27825 3.62445C4.13308 2.76945 5.13833 2.09262 6.294 1.59395C7.44967 1.09512 8.68442 0.845703 9.99825 0.845703C11.3123 0.845703 12.5473 1.09512 13.7035 1.59395C14.8597 2.09262 15.8653 2.76945 16.7205 3.62445C17.5757 4.47945 18.2528 5.48487 18.7518 6.6407C19.2506 7.79654 19.5 9.03154 19.5 10.3457V18.038C19.5 18.5351 19.323 18.9607 18.969 19.3147C18.615 19.6687 18.1894 19.8457 17.6923 19.8457H10Z"
      fill="#2F858D"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>
