<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.30775 17.8457C1.80258 17.8457 1.375 17.6707 1.025 17.3207C0.675 16.9707 0.5 16.5431 0.5 16.038V2.65345C0.5 2.14829 0.675 1.7207 1.025 1.3707C1.375 1.0207 1.80258 0.845703 2.30775 0.845703H15.6923C16.1974 0.845703 16.625 1.0207 16.975 1.3707C17.325 1.7207 17.5 2.14829 17.5 2.65345V16.038C17.5 16.5431 17.325 16.9707 16.975 17.3207C16.625 17.6707 16.1974 17.8457 15.6923 17.8457H2.30775ZM5.115 7.5957C4.9025 7.5957 4.72442 7.66762 4.58075 7.81145C4.43725 7.95512 4.3655 8.1332 4.3655 8.3457V13.3457C4.3655 13.5582 4.43742 13.7363 4.58125 13.88C4.72492 14.0238 4.90308 14.0957 5.11575 14.0957C5.32825 14.0957 5.50633 14.0238 5.65 13.88C5.7935 13.7363 5.86525 13.5582 5.86525 13.3457V8.3457C5.86525 8.1332 5.79342 7.95512 5.64975 7.81145C5.50592 7.66762 5.32767 7.5957 5.115 7.5957ZM8.99975 4.5957C8.78708 4.5957 8.609 4.66762 8.4655 4.81145C8.32183 4.95512 8.25 5.1332 8.25 5.3457V13.3457C8.25 13.5582 8.32192 13.7363 8.46575 13.88C8.60958 14.0238 8.78775 14.0957 9.00025 14.0957C9.21292 14.0957 9.391 14.0238 9.5345 13.88C9.67817 13.7363 9.75 13.5582 9.75 13.3457V5.3457C9.75 5.1332 9.67808 4.95512 9.53425 4.81145C9.39042 4.66762 9.21225 4.5957 8.99975 4.5957ZM12.8842 10.5957C12.6718 10.5957 12.4937 10.6676 12.35 10.8115C12.2065 10.9551 12.1348 11.1332 12.1348 11.3457V13.3457C12.1348 13.5582 12.2066 13.7363 12.3503 13.88C12.4941 14.0238 12.6723 14.0957 12.885 14.0957C13.0975 14.0957 13.2756 14.0238 13.4193 13.88C13.5628 13.7363 13.6345 13.5582 13.6345 13.3457V11.3457C13.6345 11.1332 13.5626 10.9551 13.4187 10.8115C13.2751 10.6676 13.0969 10.5957 12.8842 10.5957Z"
      fill="#2F858D"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>
