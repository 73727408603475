<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9676 0.98042C10.9676 0.676086 11.0815 0.432669 11.3091 0.250169C11.5366 0.0676692 11.7927 0.00658543 12.0774 0.0669188C13.9171 0.461752 15.4904 1.31942 16.7974 2.63992C18.1046 3.96042 18.9453 5.54059 19.3196 7.38042C19.3798 7.64442 19.3226 7.87767 19.1481 8.08017C18.9736 8.28284 18.7346 8.38417 18.4311 8.38417H12.8869C12.8267 8.38417 12.774 8.36909 12.7286 8.33892C12.6835 8.30875 12.6458 8.26359 12.6156 8.20342C12.4656 7.87259 12.2633 7.57725 12.0086 7.31742C11.7538 7.05759 11.4671 6.85675 11.1484 6.71492C11.0882 6.68509 11.0431 6.6515 11.0129 6.61417C10.9827 6.57684 10.9676 6.52834 10.9676 6.46867V0.98042ZM7.90814 18.5994C5.77097 18.1149 4.00622 17.0152 2.61389 15.3002C1.22156 13.5853 0.525391 11.6005 0.525391 9.34567C0.525391 7.08617 1.22156 5.10109 2.61389 3.39042C4.00622 1.67992 5.77097 0.580419 7.90814 0.0919188C8.20947 0.0214188 8.48064 0.0878362 8.72164 0.29117C8.96264 0.494503 9.08314 0.754336 9.08314 1.07067V6.50717C9.08314 6.52317 9.03631 6.58725 8.94264 6.69942C8.40814 6.92009 7.98064 7.27075 7.66014 7.75142C7.33947 8.23225 7.17914 8.76434 7.17914 9.34767C7.17914 9.931 7.33947 10.4557 7.66014 10.9217C7.98064 11.3877 8.40814 11.7278 8.94264 11.9419C8.96964 11.9521 9.01647 12.0155 9.08314 12.1322V17.6207C9.08314 17.937 8.96264 18.1968 8.72164 18.4002C8.48064 18.6035 8.20947 18.6699 7.90814 18.5994ZM11.9716 18.6399C11.7075 18.6938 11.4741 18.6338 11.2716 18.4602C11.069 18.2865 10.9676 18.0637 10.9676 17.7917V12.1977C10.9676 12.1373 10.9827 12.0846 11.0129 12.0394C11.0431 11.9943 11.0882 11.9566 11.1484 11.9264C11.4754 11.7828 11.766 11.5836 12.0201 11.3289C12.2743 11.0741 12.4728 10.7873 12.6156 10.4687C12.626 10.4482 12.7111 10.3879 12.8709 10.2879H18.4311C18.7036 10.2879 18.9307 10.4018 19.1124 10.6294C19.2941 10.8569 19.3548 11.113 19.2946 11.3977C18.9036 13.2373 18.0453 14.8107 16.7196 16.1177C15.394 17.4248 13.8113 18.2656 11.9716 18.6399Z"
      fill="#2F858D"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>
