<template>
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18.3457C1.45 18.3457 0.979333 18.15 0.588 17.7587C0.196 17.3667 0 16.8957 0 16.3457V7.3457C0 7.02904 0.0709998 6.72904 0.213 6.4457C0.354333 6.16237 0.55 5.92904 0.8 5.7457L6.8 1.2457C6.98333 1.11237 7.175 1.01237 7.375 0.945703C7.575 0.879036 7.78333 0.845703 8 0.845703C8.21667 0.845703 8.425 0.879036 8.625 0.945703C8.825 1.01237 9.01667 1.11237 9.2 1.2457L15.2 5.7457C15.45 5.92904 15.646 6.16237 15.788 6.4457C15.9293 6.72904 16 7.02904 16 7.3457V16.3457C16 16.8957 15.8043 17.3667 15.413 17.7587C15.021 18.15 14.55 18.3457 14 18.3457H10V11.3457H6V18.3457H2Z"
      fill="#2F858D"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>
